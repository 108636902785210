import React from "react"
import { Section, SubSection, RuleDivList, BrushAnimation, RuleDiv, PLinkWithArrow, HeroTitleDiv, HeroSubtitle, EmptyComponent, AccordionList, AccordionListItem, DivSeparator, StatCardList, StatCard } from "./base";

import glanceImages from "../../utils/svg/approach/index"
import MapSVG from "../../utils/svg/locations_socialdesk.svg"

const SectionImages = {
    glanceImages : glanceImages,
    MapSVG : MapSVG
}

const reactStringReplace  = require("react-string-replace");

class RuleDivTitleTemplate extends React.Component {
    render (){
        const {text, highlighted} = this.props.title;

        return <>  
            {
                highlighted ? <>
                    {
                        reactStringReplace(text, highlighted, (match, i) => (
                            <span className="prosource-rule-div--title-emphasis">{match}</span>
                        ))
                    }
                </> : <>{text}</>
            }
        </>
    }
}

class HeroTitleDivTemplate extends React.Component {
    render() {
        const {text, highlighted} = this.props.content;
        return <HeroTitleDiv> 
            {
                highlighted ?  reactStringReplace(text, highlighted.text, (match, i) => (
                    <BrushAnimation title={match} brushClassName={highlighted.brush.brushClassName} brushType={highlighted.brush.brushType}/>
                )) : {text}
            }
        </HeroTitleDiv>
    }
}

export class SectionTemplate extends React.Component {
    render(){
        const {children, details} = this.props;
        return <Section sectionName={details.title} sectionLabelRight={details.number} sectionClassName={details.className}>
            {children}
        </Section>
    }
}

class HeroDivSectionHeroTemplate extends React.Component {
    render () {
        const {className=`medium-sz-text`, text, highlighted} = this.props.content;
        if(highlighted){
            return  <span className={className}> 
            {
                reactStringReplace(text, highlighted.text, (match, i) => (
                    <BrushAnimation title={match} brushClassName={highlighted.brush.brushClassName} svgClassName={highlighted.brush.svgClassName} spanClassName={highlighted.brush.spanClassName} brushType={highlighted.brush.brushType} viewBox={highlighted.brush.viewBox}/>
                ))
            }
            </span>
        }
        else{
            return <span className={className}> {text} </span>;
        }
    }
}

class PLinkWithArrowTemplate extends React.Component {
    render () {
        const {display=true, plink} = this.props.content;

        const externalLink = plink.externalLink;
        if(display){
            return <PLinkWithArrow to={plink.to} className={plink.className} onClickFadeOut={plink.onClickFadeOut} ignoreScrollTop={plink.ignoreScrollTop} pinkArrowClassName={plink.pinkArrowClassName} externalLink={externalLink} target={plink.target}>{plink.text}</PLinkWithArrow>
        }
        else{
            return <EmptyComponent/>
        }
    }
}

export class HeroDivWithSubtitle extends React.Component {
    render () {
        const {hero, subtitle} = this.props.content, {className} = this.props;

        return <div className={className}>
            <HeroTitleDivTemplate content={hero}/>
            <HeroSubtitle>
                {
                    subtitle.map((text) => {
                        return <p>{text}</p>
                    })
                }
            </HeroSubtitle>
        </div>
    }
}

class HeroDivWithEnumerationEnumeration extends React.Component {
    render () {
        const {list, className} = this.props.content;
        return  <RuleDivList className={className}>
            {
                list.map(({title, description, number}) =>{
                    return <RuleDiv title={<RuleDivTitleTemplate title={title}/>} number={number}>{description}</RuleDiv>
                })
            }
        </RuleDivList>
    }
}

export class HeroDivWithEnumerationContent extends React.Component {
    render (){
        const {content} = this.props;

        return <>
            <SubSection>
                <div>
                    <HeroDivSectionHeroTemplate content={content.hero}/>
                </div>
            </SubSection>
            <SubSection>
                <HeroDivWithEnumerationEnumeration content={content.enumeration}/>
            </SubSection>
        </>
    }
}

export class HeroDivWithEnumeration extends React.Component {
    render() {
        const {section, content} = this.props;

        return <SectionTemplate details={section}>
            <div className={`section-div--content`}>
                <HeroDivWithEnumerationContent content={content}/>
            </div>
        </SectionTemplate>
    }
}

class HeroDivWithDescriptionTemplate extends React.Component{
    render () {
        const {text, plinkarrow} = this.props.content;
        return  <>
            <span className="section-description-span">
                {text}
            </span>
            {
                plinkarrow ? <PLinkWithArrowTemplate content={plinkarrow}/> : <EmptyComponent/>
            }
        </>
    }
}

export class HeroDivWithDescriptionContent extends React.Component {
    render () {
        const {content} = this.props;

        return <>
            <SubSection>
                <div>
                    <HeroDivSectionHeroTemplate content={content.hero}/>
                </div>
            </SubSection>
            <SubSection>
                <HeroDivWithDescriptionTemplate content={content.description}/>
            </SubSection>
        </>
    }
}

export class HeroDivWithDescription extends React.Component{
    render () {
        const {section, content} = this.props;

        return <SectionTemplate details={section}>
            <div className="section-div--content">
                <HeroDivWithDescriptionContent content={content}/>
            </div>
        </SectionTemplate>

    }
}

export class SectionAccordionListContent extends React.Component {
    render() {
        const {content} = this.props;
        return <AccordionList>
        {   
            content.list.map(({title, content, image}) =>{
                const toggleHtml = (
                    <>
                    <span className="accordion-list-item-toggle-span">
                        {title}
                    </span>
                    </>
                )

                return (
                    <AccordionListItem toggleHtml={toggleHtml}>
                    <div className="accordion-list-item--content">
                        <div className="accordion-list-item--content-text">
                            <p>{content}</p>
                        </div>
                        <div className="accordion-list-item--content-image">
                            <img alt="key" src={glanceImages[image]} />
                        </div>
                    </div>
                    </AccordionListItem>
                )
            })
        }
    </AccordionList>
    }
}

export class SectionAccordionList extends React.Component {
    render () {
        const {section, content} = this.props;

        return <SectionTemplate details={section}>
            <SectionAccordionListContent content={content}/>
        </SectionTemplate>
    }
}

export class SectionWithMultipleContents extends React.Component{
    render() {
        const {section, content} = this.props;
        return <SectionTemplate details={section}>
        </SectionTemplate>
    }
}

const TextTemplate = ({text}) =>{
    if(text){
        const {className, highlighted} = text, textContent = (text.text ? text.text : "");
        return <span className={className}>
            {
                highlighted ? reactStringReplace(textContent, highlighted.text, (match, i) => (
                    <BrushAnimation title={match} brushClassName={highlighted.brush.brushClassName} svgClassName={highlighted.brush.svgClassName} spanClassName={highlighted.brush.spanClassName} brushType={highlighted.brush.brushType} viewBox={highlighted.brush.viewBox}/>
                )) : textContent
            }
        </span>
    }
    else{
        return <EmptyComponent/>
    }
}

class ColumnDivTemplate extends React.Component {
    render () {
        const {columns = []} = this.props.content;

        return <div className="column-div">
            {
                columns.map(({text, className}) =>{
                    return <div className={`column-div-item${className ? " " + className : ""}`}>
                        <TextTemplate text={text}/>
                    </div>
                })
            }
        </div>
    }
}

class StatListDivTemplate extends React.Component {
    render () {
        const {list = []} = this.props.content;

        return <StatCardList>
            {list.map(({number, emphasizedDesc, normalDescription}, index) =>{
                return <StatCard number={number} emphasizedDesc = {emphasizedDesc} normalDescription = {normalDescription} key={`stat-card-${index}`}></StatCard>
            })}
        </StatCardList>
    }
}

class BigTextListTemplate extends React.Component {
    render () {
        const {list = []} = this.props.content;
        return <div>
            {
                list.map((client, index) =>{
                    return <span className="big-section-text" key={`about-page-client-list-${index}`}>
                        {client} 
                        {index < list.length-1 ? <span className="pink-slash">/ </span> : <></>}
                    </span>
                })
            }
        </div>
    }
}

class ImageDivTemplate extends React.Component {
    render () {
        const {image} = this.props.content;
        
        if(image) {
            return <div className="p-img-wrapper">
                <img alt={image.alt} src={SectionImages[image.src]} className={image.className}/>
            </div>
        }
        else{
            return <EmptyComponent/>
        }
    }
}

export class EmptySection extends React.Component {
    render (){
        const {section, contents = []} = this.props;
        return <SectionTemplate details={section}>
            {
                contents.map(({type, content, className, subsection=true}) =>{
                    let SubSectionContent = <></>;
                    if(type === "column_div"){
                        SubSectionContent = <ColumnDivTemplate content={content}/>
                    }
                    else if(type === "div_separator"){
                        SubSectionContent = <DivSeparator/>
                    }
                    else if(type === "stats_list"){
                        SubSectionContent = <StatListDivTemplate content={content}/>
                    }
                    else if(type === "big_text_list"){
                        SubSectionContent = <BigTextListTemplate content={content}/>
                    }
                    else if(type === "plink_arrow"){
                        SubSectionContent = <PLinkWithArrowTemplate content={content}/>
                    }
                    else if(type === "image_div"){
                        SubSectionContent = <ImageDivTemplate content={content}/>
                    }
                    else if(type === "hero_with_enumeration"){
                        SubSectionContent = <div className={`section-div--content`}>
                            <HeroDivWithEnumerationContent content={content}/>
                        </div>
                        subsection = false;
                    }
                    else if(type === "section_accordion_list"){
                        SubSectionContent = <SectionAccordionListContent content={content}/>
                        subsection = false;
                    }
                    else if(type === "hero_with_description"){
                        SubSectionContent = <div className={`section-div--content`}>
                            <HeroDivWithDescriptionContent content={content}/>
                        </div>
                        subsection = false;
                    }
                    else{
                        console.log("no config for:", type);
                    }

                    return  subsection ? <SubSection className={className}>{SubSectionContent}</SubSection> : SubSectionContent
                })
            }
        </SectionTemplate>
    }
}

export class HeroDivWithLink extends React.Component {
    render(){
        const {hero, plinkarrow} = this.props.content;
        return <>

            <HeroTitleDivTemplate content={hero}/>
            <SubSection>
                <PLinkWithArrowTemplate content={plinkarrow}/>
            </SubSection>
        </>
    }
}